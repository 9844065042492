import { createStore } from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reducer from './reducer'
const persistConfig = {
    key: 'root',
    storage,
    blacklist:['reducer']  //设置某个reducer数据不持久化，
}
  
  
const myPersistReducer = persistReducer(persistConfig, reducer)
 
const store = createStore(myPersistReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
 
const persistor = persistStore(store)



// 创建store 启用chrome [Redux Dev Tools] 插件
// const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export { persistor, store}
export default store
