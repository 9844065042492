import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import asyncComponent from '@/component/common/asyncComponent'

const Home = asyncComponent(() => import(/* webpackChunkName: 'home' */ '@/views/home'))
const Solutions = asyncComponent(() => import(/* webpackChunkName: 'home' */ '@/views/solutions/index'))
const SolutionsSchools = asyncComponent(() => import(/* webpackChunkName: 'home' */ '@/views/solutions/schools'))
const SolutionsDetail = asyncComponent(() => import(/* webpackChunkName: 'home' */ '@/views/solutions/detail'))
const Partner = asyncComponent(() => import(/* webpackChunkName: 'partner' */ '@/views/partner/index'))
const AboutUs = asyncComponent(() => import(/* webpackChunkName: 'aboutUs' */ '@/views/aboutUs/index'))
const ContactUs = asyncComponent(() => import(/* webpackChunkName: 'aboutUs' */ '@/views/aboutUs/contactUs'))
const PrivacyPolicy = asyncComponent(() => import(/* webpackChunkName: 'aboutUs' */ '@/views/aboutUs/privacyPolicy'))
const TermOfUs = asyncComponent(() => import(/* webpackChunkName: 'aboutUs' */ '@/views/aboutUs/termOfUs'))
const Download = asyncComponent(() => import(/* webpackChunkName: 'support' */ '@/views/support/download'))
const FAQ = asyncComponent(() => import(/* webpackChunkName: 'support' */ '@/views/support/faq'))
const Video = asyncComponent(() => import(/* webpackChunkName: 'support' */ '@/views/support/video'))
const ServicesPolicy =asyncComponent(() => import(/*webpackChunkName: 'support' */ '@/views/support/servicesPolicy'))
const EOLProductList =asyncComponent(() => import(/*webpackChunkName: 'support' */ '@/views/support/eolProductList'))
const Product = asyncComponent(() => import(/* webpackChunkName: 'product' */ '@/views/product/index'))
const ProductDetail = asyncComponent(() => import(/* webpackChunkName: 'product' */ '@/views/product/productDetail'))
const InquiryList = asyncComponent(() => import(/* webpackChunkName: 'product' */ '@/views/product/inquiryList'))
const Error = asyncComponent(() => import(/* webpackChunkName: 'error' */ '@/views/error/index.js'))
const Blog = asyncComponent(() => import(/* webpackChunkName: 'blog' */ '@/views/blog/index.js'))
const BlogDetail = asyncComponent(() => import(/* webpackChunkName: 'blog' */ '@/views/blog/blogDetail'))

const BasicRoute = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home}>
        <Redirect from="/" to="/home" />
      </Route>
      <Route path="/home" component={Home} />
      <Route path="/Solutions" exact component={Solutions} />
      <Route path="/SolutionsSchools" exact component={SolutionsSchools} />
      <Route path="/SolutionsDetail" exact component={SolutionsDetail} />
      <Route path="/Partner" exact component={Partner} />
      <Route path="/AboutUs" exact component={AboutUs} />
      <Route path="/ContactUs" exact component={ContactUs} />
      <Route path="/PrivacyPolicy" exact component={PrivacyPolicy} />
      <Route path="/EOLProductList" exact component={EOLProductList} />
      <Route path="/TermOfUs" exact component={TermOfUs} />
      <Route path="/Download" exact component={Download} />
      <Route path="/FAQ" exact component={FAQ} />
      <Route path="/Videos" exact component={Video} />
      <Route path="/ServicesPolicy" exact component={ServicesPolicy} />
      <Route path="/Product" exact component={Product} />
      <Route path="/ProductDetail" exact component={ProductDetail} />
      <Route path="/InquiryList" exact component={InquiryList} /> 
      <Route path="/Blog" exact component={Blog} />
      <Route path="/BlogDetail" exact component={BlogDetail}  />
      <Route path="/404" exact component={Error} />
      <Redirect from="*" to="/404" />
    </Switch>
  )
}

export default BasicRoute
