/*
 * @Author: your name
 * @Date: 2020-09-16 13:45:19
 * @LastEditTime: 2020-10-15 11:46:38
 * @LastEditors: zhang_tao18
 * @Description: In User Settings Edit
 * @FilePath: \DaHua.EDMf:\项目新地址\HuaRui\src\redux\actionCreators.js
 */
// 统一管理action

import { 
  SET_LANGUAGE, 
  ADDGOODS, 
  DECREASEGOODS, 
  ADDCATEGORY, 
  DECREASECATEGORY,
  DECREASEEMPTY,
  ADDGOODSCOUNT,
  DECREASEGOODSCOUNT,
  SETLANG
} from './actionTypes'

// 语言包
export const setLanguage = (value) => ({
  type: SET_LANGUAGE,
  value,
})
// 设置语言
export const setLang = (value)=>({
  type:SETLANG,
  value
})

// 增加子类商品
export const addGoods = (value) => ({
  type: ADDGOODS,
  value
})
//减少子类商品
export const decreaseGoods = (value) => ({
  type: DECREASEGOODS,
  value
})
// 增加商品数量
export const addGoodsCount = (value) => ({
  type: ADDGOODSCOUNT,
  value
})
// 减少商品数量
export const decreaseGoodsCount = (value) => ({
  type: DECREASEGOODSCOUNT,
  value
})
// 添加父类商品大类
export const addCategory = (value) => ({
  type: ADDCATEGORY,
  value
})
// 删除父类商品大类
export const decreaseCategory = (value) => ({
  type: DECREASECATEGORY,
  value,
})
// 清空商品
export const decreaseEmpty = (value) => ({
  type: DECREASEEMPTY,
  value,
})
