import React, { Component } from 'react' 
import { Button } from 'antd'
import '@/style/common/floatFrame.less'

class FloatFrame extends Component {
  constructor() {
    super()
    this.state = {
      showAgreement: false
    }
  }
  render() {
    const { showAgreement } = this.state
    return (
      <>
        {showAgreement && (
          <div className="float-frame-wrap container">
            <div className="agreement-content">
              Wisualarm.com uses cookies and similar technologies. Wisualarm uses functional cookies to ensure that its websites operate properly and analytical cookies to make your user experience optimal. Third-party cookies may collect data outside our websites as well. By clicking on " 
              <span>Agreen</span>
              " or by continuing to use this website, you give consent for the setting of cookies and the processing of personal data involved. More information on our 
              <a href="/policy">cookie statement</a>.
            </div>
          </div>
        )}
      </>
    )
  }
}

export default FloatFrame
