import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { ConfigProvider, notification } from 'antd'
import BasicRoute from './router'
import '@/assets/iconfont/iconfont.css'
import 'antd/dist/antd.min.css'
import '@/assets/style/index.less'
import '@/assets/style/appModal.less'
import zhCN from 'antd/es/locale/zh_CN'
import enUS from 'antd/es/locale/en_US'
import Header from './containers/common/Header'
import Footer from './containers/common/Footer'
import FloatFrame from './containers/common/FloatFrame'
import lang from '@/utils/lang'
import { connect } from 'react-redux'
import { setLanguage } from '@/redux/actionCreators'
import { languagePackage } from '@/api/common'
import RightFlex from '@/containers/common/RightFlex.js'


class App extends Component {
  constructor(){
    super()
    this.state={
      showHead:true
    }
    this.scrollPrev = 0
    this.scrollMode = this.scrollMode.bind(this)
  }
  async UNSAFE_componentWillMount(){
    let {data} = await languagePackage()
    this.props.setLanguage(data.languages)
    window.addEventListener('scroll', this.scrollMode)
  }
  componentWillUnmount(){
    window.removeEventListener('scroll')
  }
  // 判断页面滚动高度，确定头部的展示形式
  scrollMode() {
    // 获取滚动数值需按照下面顺序取值,兼容性最好,页面滚动时页面显示与隐藏会有问题
    var scrollTop = document.documentElement.scrollTop ||  document.body.scrollTop || window.pageYOffset 
    var scroll = scrollTop - this.scrollPrev
    this.scrollPrev = scrollTop
    localStorage.setItem('showHead',scroll < 0)
    this.setState({
      showHead: scroll < 0,
    })
    
  }
  render() {
    const {showHead}=this.state
    return (
      <Fragment>
        <Router basename={`${lang}`}>
          <ConfigProvider locale={enUS}>
            <Header showHead={showHead} />
            <FloatFrame />
            <BasicRoute />
            <RightFlex/>
            <Footer />
          </ConfigProvider>
        </Router>
      </Fragment>
    )
  }
}
const dispatchToProps = (dispatch) => {
  return {
    setLanguage(data) {
      dispatch(setLanguage(data))
    },
  }
}
const stateToProps = (state) => {
  return {
    language: state.language,
  }
}
export default connect(stateToProps,dispatchToProps)(App);
