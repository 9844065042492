import axios from 'axios'

let config = {
  timeout: 120000, // 60秒超时时间
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  accept: 'application/json, text/plain, */*',
}

const _axios = axios.create(config)

//请求拦截
_axios.interceptors.request.use(config => {
  return config
},error => {
  return error;
})

// 响应拦截
_axios.interceptors.response.use(res => {
  return res.data
},error => {
  return error;
})

export function http(url, params, method = 'get', options = null, cancelToken = null, useQs = true) {
  if (method === 'post') {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: params,
        method,
        options,
        cancelToken,
        useQs,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  return new Promise((resolve, reject) => {
    _axios({
      url,
      params,
      method,
      options,
      cancelToken,
      useQs,
    })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
