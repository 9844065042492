var _0x58b011 = _0x157a;
function _0x157a(_0x2755d8, _0x2fbfe0) {
    var _0x198e1c = _0xa0c8();
    return _0x157a = function (_0x3b50bc, _0xcab884) {
        _0x3b50bc = _0x3b50bc - (0x1517 + -0x9db + -0x1 * 0xa00);
        var _0x31e672 = _0x198e1c[_0x3b50bc];
        if (_0x157a['WLkzKn'] === undefined) {
            var _0x186098 = function (_0x18d955) {
                var _0x5b343b = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/=';
                var _0x254b07 = '', _0x38bf39 = '';
                for (var _0x5cda66 = 0x3e * -0x6b + -0x6 * 0x338 + -0x33b * -0xe, _0x4e1a5d, _0x566acc, _0x257a6d = 0xeb2 + -0x1a96 + -0x4 * -0x2f9; _0x566acc = _0x18d955['charAt'](_0x257a6d++); ~_0x566acc && (_0x4e1a5d = _0x5cda66 % (-0xe6f * 0x2 + -0x21 * 0xbb + 0x34fd) ? _0x4e1a5d * (-0x24 * -0xa5 + 0x7ae + -0x1ea2) + _0x566acc : _0x566acc, _0x5cda66++ % (-0xc90 + 0x1623 * -0x1 + 0x22b7)) ? _0x254b07 += String['fromCharCode'](0x1 * 0xfcb + 0x5b2 + 0x56 * -0x3d & _0x4e1a5d >> (-(0x3c + 0xf98 + -0xfd2) * _0x5cda66 & 0x952 * -0x1 + 0x18e2 + -0xf8a)) : -0x3 * 0x8b4 + -0x75c * 0x2 + -0x86 * -0x4e) {
                    _0x566acc = _0x5b343b['indexOf'](_0x566acc);
                }
                for (var _0xf6a28d = -0x2 * -0x685 + -0xadb * -0x2 + 0x8b0 * -0x4, _0x4a45f9 = _0x254b07['length']; _0xf6a28d < _0x4a45f9; _0xf6a28d++) {
                    _0x38bf39 += '%' + ('00' + _0x254b07['charCodeAt'](_0xf6a28d)['toString'](0x1 * 0x21dd + -0x4c4 + -0x1 * 0x1d09))['slice'](-(0x8af * -0x1 + -0x1d8a + 0x263b));
                }
                return decodeURIComponent(_0x38bf39);
            };
            _0x157a['wTfrVh'] = _0x186098, _0x2755d8 = arguments, _0x157a['WLkzKn'] = !![];
        }
        var _0x10fc6e = _0x198e1c[0x230f + -0x1cfd + -0x612], _0x2d3aa2 = _0x3b50bc + _0x10fc6e, _0x3d077b = _0x2755d8[_0x2d3aa2];
        return !_0x3d077b ? (_0x31e672 = _0x157a['wTfrVh'](_0x31e672), _0x2755d8[_0x2d3aa2] = _0x31e672) : _0x31e672 = _0x3d077b, _0x31e672;
    }, _0x157a(_0x2755d8, _0x2fbfe0);
}
(function (_0x5dba9a, _0x49014e) {
    var _0x187a12 = _0x157a, _0x2d8578 = _0x5dba9a();
    while (!![]) {
        try {
            var _0x137709 = -parseInt(_0x187a12(0x142)) / (-0xdf4 + -0x1 * -0x1a10 + -0x409 * 0x3) + -parseInt(_0x187a12(0x158)) / (0x1 * 0x19ab + 0x313 + -0x1cbc) * (parseInt(_0x187a12(0x16b)) / (-0x29 * -0xea + 0x2a1 * -0x5 + 0xc29 * -0x2)) + parseInt(_0x187a12(0x14c)) / (0x2535 + 0x41b * 0x1 + -0x294c) + -parseInt(_0x187a12(0x145)) / (0xab7 + -0x2453 + 0x3 * 0x88b) * (parseInt(_0x187a12(0x167)) / (0x23 * 0x88 + -0x2691 + -0x13ff * -0x1)) + -parseInt(_0x187a12(0x144)) / (0x2123 * -0x1 + 0x21cc + 0x2 * -0x51) * (parseInt(_0x187a12(0x159)) / (-0x6 * 0x213 + -0x1e6b + -0x8b * -0x4f)) + -parseInt(_0x187a12(0x14e)) / (0x1a * -0xa7 + -0x4 * 0xf6 + 0x37 * 0x61) * (parseInt(_0x187a12(0x154)) / (-0x1c8d * -0x1 + -0x7ae + 0x1 * -0x14d5)) + parseInt(_0x187a12(0x13c)) / (0x2ba * 0x8 + 0x200f + -0x35d4);
            if (_0x137709 === _0x49014e)
                break;
            else
                _0x2d8578['push'](_0x2d8578['shift']());
        } catch (_0x2e90eb) {
            _0x2d8578['push'](_0x2d8578['shift']());
        }
    }
}(_0xa0c8, -0x10a * -0x3f1 + -0x1b257 + 0x1490b * 0x1));
function _0xa0c8() {
    var _0x44c522 = [
        'vLL6CvG',
        'mJK2ntHIvNf2v3q',
        'CL9TB3jLl2XHBG',
        'mtqYnZy2ndDQB3LcuNe',
        'Ag9Tzs9LBwfPBa',
        'Aw9Ul2XHBMC',
        's0Dqrfu',
        'DY9Syw5N',
        'y2THz2u',
        'otCXndroA05dA0C',
        'Ag9Tzs9MB290zq',
        'mZe4odiXm3zRsxLhtG',
        'ntv5zvfYseG',
        'zM9VDgvY',
        'BMDQrgq',
        'y29Uy2f0',
        'yMfUBMvYtw9Yzq',
        'C29SDxrPB24VCW',
        'Ag9Tzs9MB2XSBW',
        'mtqYntq0nePOuuLIwG',
        'CI9Syw5N',
        'mZzLuMrewNq',
        'Ag9Tzs9Iyw5Uzq',
        'B2X1DgLVBI9Syq',
        'x3n1yNnJCMLWDa',
        'BLHLtLu',
        'sw50zxjMywnLlW',
        'mZyYmJKWrvjPrwDd',
        'ywDL',
        'r1zfqxK',
        'BgfUz3vHz2vZ',
        'nJjzqKDKCM4',
        'ohjmsvzbqq',
        'ywDLx3DHCc9Syq',
        'qM5QEuG',
        'yMfUBMvY',
        'C29SDxrPB24',
        'zM9SBg93',
        'Cg9ZDa',
        'wxnIAxe',
        'Ag9Tzs9Syw5NDq',
        'rezeDKe',
        'C3vIC2nYAwjLrq',
        'BwfPBa',
        'Duvzuwm',
        'l2fWAs9MCM9UDa',
        'mJiYotKWEgLOsxDq',
        'Cvbgvhe',
        'BgfUz3vHz2vqyq'
    ];
    _0xa0c8 = function () {
        return _0x44c522;
    };
    return _0xa0c8();
}
import { http } from '@/utils/axios';
import _0x3f7f98 from '@/utils/lang';
var api = {
    'subscribeEmail': (_0x58b011(0x166) + _0x58b011(0x153) + _0x58b011(0x13d) + _0x58b011(0x151) + _0x58b011(0x13e))[_0x58b011(0x148)](_0x3f7f98),
    'footer': (_0x58b011(0x166) + _0x58b011(0x153) + _0x58b011(0x143) + _0x58b011(0x14d))[_0x58b011(0x148)](_0x3f7f98),
    'follow': (_0x58b011(0x166) + _0x58b011(0x153) + _0x58b011(0x14b) + _0x58b011(0x140))[_0x58b011(0x148)](_0x3f7f98),
    'languages': _0x58b011(0x166) + _0x58b011(0x153) + _0x58b011(0x161) + _0x58b011(0x155),
    'languagePackage': (_0x58b011(0x166) + _0x58b011(0x153) + _0x58b011(0x161) + _0x58b011(0x15a) + 'ng')[_0x58b011(0x148)](_0x3f7f98),
    'solution': (_0x58b011(0x166) + _0x58b011(0x153) + _0x58b011(0x14a) + _0x58b011(0x150) + 'ng')[_0x58b011(0x148)](_0x3f7f98),
    'banner': (_0x58b011(0x166) + _0x58b011(0x153) + _0x58b011(0x14f) + _0x58b011(0x14d))[_0x58b011(0x148)](_0x3f7f98),
    'bannerMore': (_0x58b011(0x166) + _0x58b011(0x153) + _0x58b011(0x14f) + _0x58b011(0x16c) + 'g')[_0x58b011(0x148)](_0x3f7f98)
};
export var subscribeEmail = function subscribeEmail(_0x310e0b) {
    var _0x4e821c = _0x58b011, _0xc2a280 = {
            'ngjDd': function (_0x428e63, _0x32be73, _0x591147, _0x16b0b1) {
                return _0x428e63(_0x32be73, _0x591147, _0x16b0b1);
            },
            'qPFTq': _0x4e821c(0x15f)
        };
    return _0xc2a280[_0x4e821c(0x147)](http, api[_0x4e821c(0x163) + _0x4e821c(0x164)], _0x310e0b, _0xc2a280[_0x4e821c(0x168)]);
};
export var footer = function footer() {
    var _0x27522a = _0x58b011, _0x56c0c4 = {
            'DFDvA': function (_0x395674, _0x36bd30) {
                return _0x395674(_0x36bd30);
            }
        };
    return _0x56c0c4[_0x27522a(0x162)](http, api[_0x27522a(0x146)]);
};
export var follow = function follow() {
    var _0x26e9df = _0x58b011, _0x54f8da = {
            'Ysbiq': function (_0xca2405, _0xb6c61d) {
                return _0xca2405(_0xb6c61d);
            }
        };
    return _0x54f8da[_0x26e9df(0x160)](http, api[_0x26e9df(0x15e)]);
};
export var languages = function languages() {
    var _0x12bbe2 = _0x58b011, _0x97900b = {
            'nXeNU': function (_0x2d6626, _0x44d770) {
                return _0x2d6626(_0x44d770);
            }
        };
    return _0x97900b[_0x12bbe2(0x152)](http, api[_0x12bbe2(0x157)]);
};
export var languagePackage = function languagePackage() {
    var _0x1288d9 = _0x58b011, _0x1fdc87 = {
            'VYzqX': function (_0x3a9d81, _0xcbc414) {
                return _0x3a9d81(_0xcbc414);
            }
        };
    return _0x1fdc87[_0x1288d9(0x16a)](http, api[_0x1288d9(0x169) + _0x1288d9(0x141)]);
};
export var solution = function solution(_0x208831) {
    var _0x39e7a7 = _0x58b011, _0x1d5960 = {
            'BnjyH': function (_0x5c8f2a, _0x5955a9, _0x395252, _0xd3a9f) {
                return _0x5c8f2a(_0x5955a9, _0x395252, _0xd3a9f);
            },
            'uEYQc': _0x39e7a7(0x15f)
        };
    return _0x1d5960[_0x39e7a7(0x15b)](http, api[_0x39e7a7(0x15d)], _0x208831, _0x1d5960[_0x39e7a7(0x165)]);
};
export var banner = function banner(_0x1e5de8) {
    var _0xe59f9 = _0x58b011, _0x53d059 = {
            'GVEAy': function (_0xeba90f, _0x492112, _0x3602e8) {
                return _0xeba90f(_0x492112, _0x3602e8);
            }
        };
    return _0x53d059[_0xe59f9(0x156)](http, api[_0xe59f9(0x15c)], { 'id': _0x1e5de8 });
};
export var bannerMore = function bannerMore(_0x813b1a) {
    var _0x1f6a5b = _0x58b011, _0x1ff869 = {
            'KGPDU': function (_0x1e791c, _0x148649, _0x4cf88c) {
                return _0x1e791c(_0x148649, _0x4cf88c);
            }
        };
    return _0x1ff869[_0x1f6a5b(0x13f)](http, api[_0x1f6a5b(0x149)], _0x813b1a);
};