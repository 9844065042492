import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "@/style/common/rightFlex.less";
import { Badge } from 'antd'

class PublicBanner extends Component {
  static defaultProps = {
    isHover: false,
  };
  constructor() {
    super();
    this.state = {
      showButton: false,
      scrollHeight: 0,
    };
  }

  handleHeight() {
    if (document.body.clientWidth <= 768) {
      const { pathname } = this.props.history.location;
      if (pathname == "/ContactUs") {
        this.setState({
          localHeight: "100vh",
        });
      } else {
        this.setState({
          localHeight: "50vh",
        });
      }
    } else {
      this.setState({
        localHeight: "",
      });
    }
  }
  // 前往购物车页
  goShopStore() {
    this.props.history.push('/InquiryList')
  }
  //回到顶部点击事件
  handleClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  UNSAFE_componentWillMount() {
    this.handleHeight();
    window.addEventListener("resize", () => {
      this.handleHeight();
    });
  }
  async componentDidMount() {
    //设置监听事件，滚动条大于50就出现回到顶部按钮
    const handleScroll = () => {
      this.setState({
        scrollHeight: window.scrollY,
      });
      if (window.scrollY > 1000) {
        this.setState({ showButton: true });
      } else {
        this.setState({ showButton: false });
      }
    };
    window.addEventListener("scroll", handleScroll);
  }
  //周期结束以前移出监听事件
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleConnect() {
    this.props.history.push("/ContactUs");
  }
  render() {
    const { language, shopCart } = this.props;
    const { showButton } = this.state
    let goodsCount = 0
    if (shopCart && shopCart.length) {
      goodsCount = shopCart.map(i => i.goods_list).flat().reduce((pre, cur) => {
        return pre + Number(cur.goods_count)
      }, 0)
    }
    const shoppingCart = language.lang_shopping_cart_status
    return (
      <div>
        <div className="hover-content" onClick={this.handleConnect.bind(this)}>
          <div>
            <span className="normal"></span>
            <span>{language.lang_banner_contact}</span>
          </div>
        </div>
        <div className="custom-btn-container-cls">
          {/* 购物车 */}
          {shoppingCart !== '0' ? <Badge count={goodsCount > 99 ? 99 : goodsCount} color="#ff0000" className="custom-badge-cls">
            <div className="back-to-top" onClick={this.goShopStore.bind(this)}>
              <i className="iconfont icon-gouwuche"></i>
            </div>
          </Badge> : ''}
          {showButton &&
            <div className="back-to-top" onClick={this.handleClick}>
              <i className="iconfont icon-shangjiantou back-arrow"></i>
            </div>
          }
        </div>
      </div>
    );
  }
}

const stateToProps = (state) => {
  return {
    language: state.language,
    shopCart: state.shopCart,
  };
};
export default connect(stateToProps, null)(withRouter(PublicBanner));
