/*
 * @Author: your name
 * @Date: 2020-09-16 13:45:19
 * @LastEditTime: 2020-10-15 11:46:48
 * @LastEditors: zhang_tao18
 * @Description: In User Settings Edit
 * @FilePath: \DaHua.EDMf:\项目新地址\HuaRui\src\redux\actionTypes.js
 */
// action types 统一管理

/*********** 语言包 ***********/
export const SET_LANGUAGE = 'set_language'
/**设置语言 */
export const SETLANG='set_lang'
/**添加子类商品 */
export const ADDGOODS = 'add_goods'
/**减少父类商品 */
export const DECREASEGOODS = 'decrease_goods'
/**添加父类商品大类 */
export const ADDCATEGORY = 'add_category'
/**删除父类商品大类 */
export const DECREASECATEGORY = 'decrease_category'
/**清空商品 */
export const DECREASEEMPTY = 'decrease_empty'
/**增加商品数量 */
export const ADDGOODSCOUNT = 'add_goods_count'
/**减少商品数量 */
export const DECREASEGOODSCOUNT= 'decrease_goods_count'

