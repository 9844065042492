import { 
  SET_LANGUAGE, 
  SETLANG,
  ADDGOODS, 
  DECREASEGOODS, 
  ADDCATEGORY, 
  DECREASECATEGORY,
  DECREASEEMPTY,
  ADDGOODSCOUNT,
  DECREASEGOODSCOUNT,
} from './actionTypes'
// reducer 修改 store
const defaultState = {
  lang: 'en',
  language: {},
  shopCart: []
}
localStorage.setItem('lang',defaultState.lang)
function operation(shopCart,value){
  const {parentIndex,childIndex}=value
  const parentList = shopCart[parentIndex].goods_list
  const child=parentList[childIndex]
  return {parentList,child}
}
export default (state = defaultState, { type, value }) => {
  let { shopCart } = state
  switch (type) {
    //设置语言包
    case SET_LANGUAGE:
      return {
        ...state,
        language: value,
      }
    //设置语言
    case SETLANG:
      localStorage.setItem('lang',value)
      return {
        ...state,
        lang:value
      }
    // 增加子类商品
    case ADDGOODS:
      /**已存在父类商品判断是否有子类商品,有则在原来基础上增加数量,没有则添加子类商品到父类商品
       * flag:false，不存在
       * flag:true 存在
       */
      
      let flag = false
      shopCart.forEach((item) => {
        item.goods_list && item.goods_list.forEach((chilItem) => {
          if (chilItem.goods_name == value.goods.goods_name) {
            flag = true
          }
        })
      })
      if (flag) {
        shopCart.forEach((item) => {
          item.goods_list.forEach((chilItem) => {
            if (chilItem.goods_name == value.goods.goods_name) {
              chilItem.goods_count = Number(chilItem.goods_count) + Number(value.goods.goods_count)
            }
          })
        })
      } else {
        shopCart.forEach((item) => {
          if (item.title == value.title) {
            item.goods_list = [...item.goods_list, value.goods]
          }
        })
      }
      return {
        ...state,
        shopCart: [...shopCart]
      }
    // 减少子类商品
    case DECREASEGOODS:
      const {parentList:parentDC}=operation(shopCart,value)
      parentDC.splice(value.childIndex,1)
      return {
        ...state,
        shopCart:[...shopCart]
      }
    // 添加种类
    case ADDCATEGORY:
      return {
        ...state,
        shopCart: [...state.shopCart, value]
      }
    // 减少种类
    case DECREASECATEGORY:
      shopCart.splice(value.parentIndex,1)
      return {
        ...state,
        shopCart:[...shopCart]
      }
    // 清空商品
    case DECREASEEMPTY:
      // shopCart.splice(value.parentIndex,1)
      return {
        ...state,
        shopCart:[]
      }
    // 增加子类商品数量
    case ADDGOODSCOUNT:
      const {child:achild}=operation(shopCart,value)
      achild.goods_count+=1
      return {
        ...state,
        shopCart:[...shopCart]
      }
    // 减少子类商品数量
    case DECREASEGOODSCOUNT:
      const {child:dchild}=operation(shopCart,value)
      dchild.goods_count+=-1
      return {
        ...state,
        shopCart:[...shopCart]
      }
    default:
      return state
  }
}
